<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12" class="mb-2">
        <b-alert  show dismissible fade class="mb-0" variant="info">
          <div class="alert-body">
            <span>{{
                $t(
                  "Pages.Studio.Title.only_public_status"
                )
            }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12" sm="12" class="mb-2" v-if="show_alert_permission">
        <b-alert  show dismissible fade class="mb-0" variant="danger">
          <div class="alert-body">
            <span>{{
                $t(
                  "Pages.Studio.Title.ch_access_incomplet"
                )
            }}</span>
          </div>
        </b-alert>
      </b-col>
      <template  v-for="(video,index) in pending_upload_list" >
        <b-col lg="12" sm="12" class="mb-2" v-if="pending_upload_list.length > 0 && !show_alert_get_list_video" :key="index">
          <b-alert  show dismissible fade class="mb-0" variant="warning">
            <div class="alert-body">
              <span>{{
                  $t(
                    "Pages.Studio.Title.vid_in_upload_queue" , {name_video:video.title}
                  )
              }}</span>
            </div>
          </b-alert>
        </b-col>
      </template>

      <b-col cols="12">
        <b-row class="d-flex justify-content-around text-center">
          <b-col lg="12" sm="12">
            <b-card class="col-12">
              <b-card-body>
                <b-row>
                  <b-input-group class="input-group-merge">
                    <b-form-input @keyup="getList" v-model="filters.q"
                      :placeholder="$t('Pages.Studio.Placeholder.search_video')" class="search-product" />
                    <b-input-group-append is-text>
                      <feather-icon icon="SearchIcon" class="text-muted" />
                    </b-input-group-append>
                  </b-input-group>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12" sm="12">
        <b-card v-if="tableData" no-body class="card-company-table">
          <b-overlay :show="show_alert_get_list_video" spinner-variant="primary" variant="transparent" spinner-type="grow" spinner-small
            rounded="sm">
            <b-table :items="tableData" responsive :fields="fields" primary-key="id"  show-empty
              :empty-text="$t('Pages.Studio.Table.no_vid_found')" :sort-desc.sync="isSortDirDesc"
              class="position-relative">
              <template #cell(thumbnail)="data">
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img class="img-fluid" :src="data.item.thumbnail" alt="avatar img" />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      {{ data.item.title }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.description.slice(0, 255) }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(privacy_status)="data">
                <div class="d-flex flex-column">
                  <span>
                    <b-badge pill variant="light-primary" v-if="data.item.privacy_status == 'public'">{{
                        $t("Pages.Studio.Table.Public")
                    }}
                    </b-badge>
                    <b-badge pill variant="light-primary" v-if="data.item.privacy_status == 'private'">{{
                        $t("Pages.Studio.Table.Private")
                    }}</b-badge>
                    <b-badge pill variant="light-primary" v-if="data.item.privacy_status == 'unlisted'">{{
                        $t("Pages.Studio.Table.Unlisted")
                    }}</b-badge>
                  </span>
                </div>
              </template>

              <template #cell(publishedAt)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">{{
                      MyDate.getFormatDateTime(data.item.published_at)
                  }}</span>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <b-img
                  :src="
                    require('@/assets/images/icons/youtube_social_icon_red.png')
                  "
                  height="16px"
                  width="auto"
                  alt="Youtube"
                  class="mx-1 cursor-pointer"
                  @click="openYoutube(data.item.video_id)"
                />
                  <span @click="OpenModalUpdateVideo(data.item)">
                    <feather-icon icon="EditIcon" size="16" class="mx-1 cursor-pointer" v-b-modal.modal-login />
                  </span>
                  <span @click="deleteVideo(data.item.video_id)">
                    <feather-icon icon="TrashIcon" size="16" class="mx-1 cursor-pointer text-danger" />
                  </span>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-if="pages > 1">
        <b-pagination-nav :link-gen="linkGen" :number-of-pages="pages" use-router class="mb-0" />
      </b-col>
    </b-row>

    <b-modal id="modal-login" cancel-variant="outline-secondary" :ok-title="formUpdate.btn" hide-backdrop
      no-close-on-backdrop cancel-title="لغو" centered size="lg" :title="formUpdate.titleForm" @ok="UpdateVideo">
      <b-form>
        <div class="text-center" style="position: relative">
          <b-embed width="400px" type="iframe" aspect="16by9"
            :src="'https://www.youtube.com/embed/' + formUpdate.video_id" allowfullscreen />
        </div>
        <b-form-group>
          <label for="title">{{ this.$t("Pages.Studio.Label.Title") }}:</label>
          <b-form-input id="title" type="text" v-model="formUpdate.title" required />
        </b-form-group>
        <b-form-group>
          <label for="description">{{ this.$t("Pages.Studio.Label.Description") }}:</label>
          <b-form-textarea id="description" type="text" v-model="formUpdate.description" rows="8" />
        </b-form-group>
        <b-form-group label-for="vue-playlist">
          <label class="mt-1" for="playlist">{{ this.$t("Pages.Studio.Label.Playlists") }}:</label>
          <v-select id="vue-playlist" v-model="formUpdate.playlistsSelect"
            :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'" :options="playlists" />
        </b-form-group>
        <b-form-group>
          <label for="tags">{{ this.$t("Pages.Studio.Label.Tags") }}:</label>
          <b-form-tags id="tags" v-model="formUpdate.tags" rows="8" />
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="defaultAudioLanguage">{{ this.$t("Pages.Studio.Label.default_audio_lang")
          }}:</label>
          <v-select id="vue-select" v-model="formUpdate.defaultAudioLanguage"
          :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'" :options="AudioLanguage" />
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="category">{{ this.$t("Pages.Studio.Label.Category") }}:</label>
          <v-select id="vue-select" v-model="formUpdate.category"
          :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'" :options="Category" />
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="privacyStatus">{{ this.$t("Pages.Studio.Label.privacy_status") }}:</label>
          <v-select id="vue-select" v-model="formUpdate.privacyStatus"
          :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'" :options="PrivacyStatus" />
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="selfDeclaredMadeForKids">{{ this.$t("Pages.Studio.Label.made_for_kids")
          }}:</label>
          <v-select id="vue-select" v-model="formUpdate.selfDeclaredMadeForKids"
          :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
            :options="selfDeclaredMadeForKids" />
        </b-form-group>

        <div class="text-center mt-2" style="position: relative" v-if="formUpdate.thumbnails_prew != ''">
          <div style="position: absolute">
            <b-badge @click="ChangeThumbnail" variant="danger">{{ $t("Pages.Studio.Button.change_thumbnail")
            }}</b-badge>
          </div>
          <img :src="formUpdate.thumbnails_prew" width="400px" />
        </div>
        <div class="text-center mt-1" style="position: relative" v-if="formUpdate.thumbnails">
          <b-img width="400px" type="iframe" :src="formUpdate.thumbnails" />
        </div>
        <b-form-group label-for="vue-select" class="mt-2">
          <label for="selfDeclaredMadeForKids">{{ this.$t("Pages.Studio.Label.Thumbnail") }}:</label>
          <b-form-file :placeholder="$t('Pages.Studio.Placeholder.select_thumbnail')" :drop-placeholder="$t('Pages.Studio.Placeholder.select_thumbnail')" no-drop
            @change="SelectThumbnailUpdateVideo" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BAlert,
  BBadge,
  BButton,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BInputGroup,
  BInputGroupAppend,
  BCardHeader,
  BCardBody,
  BOverlay,
  BPaginationNav,
  BFormInput,
  BModal,
  VBModal,
  BFormTextarea,
  BFormFile,
  BFormTags,
  VBTooltip,
  BEmbed,
  BFormGroup,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import MyDate from "@core/components/date/MyDate.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BCardHeader,
    vSelect,
    BOverlay,
    BPaginationNav,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BModal,
    BFormTextarea,
    BFormFile,
    BFormTags,
    BEmbed,
    BFormGroup,
    BForm
  },
  data() {
    return {
      MyDate,
      show: false,
      tableData: [],
      pages: 0,
      fields: [
        { key: "thumbnail", label: this.$t("Pages.Studio.Table.title_video") },
        { key: "privacy_status", label: `${this.$t("Pages.Studio.Table.Status")}`, },
        { key: "publishedAt", label: `${this.$t("Pages.Studio.Table.publishedAt_video")}` },
        { key: "actions", label: this.$t("Pages.Studio.Table.Operation") },
      ],
      isSortDirDesc: true,
      filters: {
        q: "",
      },
      playlists: [],
      AudioLanguage: [],
      Category: [],
      PrivacyStatus: [
        { id: 2, label: "public", value: "public" },
        { label: "private", value: "private" },
        { label: "unlisted", value: "unlisted" },
      ],

      selfDeclaredMadeForKids: [
        { label: this.$t("Pages.Studio.Title.Yes"), value: "true" },
        { label: this.$t("Pages.Studio.Title.No"), value: "false" },
      ],
      formUpdate: {
        video_id: 0,
        thumbnails_prew: "",
        thumbnails: "",
        title: "",
        description: "",
        tags: "",
        defaultAudioLanguage: {},
        category: "",
        playlistsSelect: this.$t("Pages.Studio.Title.not_playlist"),
        privacyStatus: "public",
        btn: this.$t("Pages.Studio.Button.btn_update_vid"),
        titleForm: this.$t("Pages.Studio.Title.title_model_update_vid"),
        selfDeclaredMadeForKids: { label: this.$t("Pages.Studio.Title.No"), value: "false" },
      },
      show_alert_permission: false,
      show_alert_get_list_video: true,
      PermissionUpdateVideo: false,
      PermissionDeleteVideo: false,
      count_coin: 0,
      details:{},
      first_request:true,
      pending_upload_list:[]
    };
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.$http.get("/api/check_login").then(({ data }) => {
      let permissions = [];
      if (data.permissions.length != 0) {
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
      }
      this.getList();
      if (permissions.includes("Update Video")) {
        this.PermissionUpdateVideo = true;
      }
      if (permissions.includes("Delete Video")) {
        this.PermissionDeleteVideo = true;
      }
    });
  },
  mounted() {
    this.getCountCoin()
    this.getUseDetails()
    this.checkPermissionYoutubeSSL()
    this.getStaticDataInForm()
    this.getListPendingVideoUplaod()
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getList();
      }
    },
  },
  methods: {
    getUseDetails() {
      this.$http
        .get(`/api/channel/studio/videos/use-details`)
        .then((response) => {
          this.details = response.data;
        });
    },
    openYoutube(channelId) {
      window.open(`https://www.youtube.com/channel/${channelId}`, "_blank");
    },
    getCountCoin() {
      this.$http
        .get(`/api/dashboard/getCountCoin`)
        .then((response) => {
          this.count_coin = response.data.count_coin;
        });
    },
    getList() {
      this.show = true;
      let page = typeof this.$route.query.page !== "undefined" ? this.$route.query.page : 1;
      let text = this.filters.q == "" ? "" : this.filters.q;
      if(this.first_request){
        this.$http.get(`/api/channel/studio/videos/update-list?channel_id=${this.$route.params.channel_id}`)
        .then((response) => {
          if (response.data.status || response.data.status == 'true') {
            this.show_alert_get_list_video = false,
              this.$http.get(`/api/channel/studio/videos/getList?page=${page}`, {
                params: {
                  channel_id: this.$route.params.channel_id,
                  title: text,
                },
              })
                .then((response) => {
                  this.show = false;
                  this.tableData = response.data.data;
                  this.pages = response.data.last_page;
                })
          }
        })
        this.first_request = false;
      }else{
        this.$http.get(`/api/channel/studio/videos/getList?page=${page}`, {
          params: {  channel_id: this.$route.params.channel_id,  title: text, },
          })
           .then((response) => {
            this.show = false;
            this.tableData = response.data.data;
            this.pages = response.data.last_page;
          })
      }
      
    },
    checkPermissionYoutubeSSL() {
      this.$http.get(`/api/channel/studio/checkPermission`, {
        params: {
          channel_id: this.$route.params.channel_id,
        },
      })
        .then((res) => {
          if (res.data.success == false) {
            this.show_alert_permission = true;
          } else {
            this.show_alert_permission = false;
          }
        });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    OpenModalUpdateVideo(data) {
      this.formUpdate.video_id = data.video_id;
      this.formUpdate.title = data.title;
      this.formUpdate.tags = data.tags;
      this.formUpdate.description = data.description;

      this.playlists.map((item) => {
        if (item.value == data.playlist_id) {
          this.formUpdate.playlistsSelect = {
            label: item.label,
            value: item.value,
          };
        }
      });

      this.AudioLanguage.map((item) => {
        if (item.value.includes(data.default_audio_language)) {
          this.formUpdate.defaultAudioLanguage = {
            label: item.label,
            value: item.value,
          };
        }
      });

      this.Category.map((item) => {
        if (item.value == data.categoryId) {
          this.formUpdate.category = {
            label: item.label,
            value: item.value,
          };
        }
      });

      this.PrivacyStatus.map((item) => {
        if (item.value == data.privacy_status) {
          this.formUpdate.privacyStatus = {
            label: item.label,
            value: item.value,
          };
        }
      });
    },
    UpdateVideo() {
      if (this.PermissionUpdateVideo) {
        if (this.count_coin > 1 || this.details.update < 2) {
          this.makeToast(this.$t("Base.Alert.Successful"), this.$t("Base.Alert.please_wait"), "success");
          let data = new FormData();
          data.append("video_id", this.formUpdate.video_id);
          data.append("channel_id", this.$route.params.channel_id);
          data.append("title", this.formUpdate.title);
          data.append("description", this.formUpdate.description);
          if (this.formUpdate.thumbnails_prew) {
            data.append("thumbnail", this.formUpdate.thumbnails);
          }
          data.append("tags", this.formUpdate.tags);
          data.append(
            "default_audio_language",
            this.formUpdate.defaultAudioLanguage.value
          );
          data.append("category_id", this.formUpdate.category.value);
          data.append("privacy_status", this.formUpdate.privacyStatus.value);
          if (this.formUpdate.playlistsSelect.value) {
            data.append("playlist_id", this.formUpdate.playlistsSelect.value);
          }
          data.append(
            "self_declared_made_for_kids",
            this.formUpdate.selfDeclaredMadeForKids.value
          );
          this.$http.post(`/api/channel/studio/videos/update`, data)
            .then((response) => {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Pages.Studio.Alert.success_update_vid"),
                "success"
              );
              this.getList();
            })
        } else {
          this.$swal({
            title: this.$t("Base.Alert.Error"),
            text: this.$t("Base.Alert.not_enough_coin"),
            icon: 'warning',
            confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push({ name: 'MasterCoin' }).catch(() => { });
            }
          })
        }

      } else {
        this.openPermissionDenid()
      }

    },

    SelectThumbnailUpdateVideo(event) {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = function (e) {
          this.formUpdate.thumbnails_prew = e.target.result;
          videoTag.load();
        }.bind(this);

        reader.readAsDataURL(event.target.files[0]);
        this.formUpdate.thumbnails = event.target.files[0];
      }
    },

    deleteVideo(video_id) {
      if (this.PermissionDeleteVideo) {
        if(this.count_coin > 1 || this.details.delete < 2){
            this.$swal({
            title: this.$t("Base.Alert.are_sure"),
            text: this.$t("Base.Alert.wont_able_revert"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t("Base.Alert.del_it"),
            cancelButtonText: this.$t("Base.Alert.Cancel"),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$http.post(`/api/channel/studio/videos/delete`, { channel_id: this.$route.params.channel_id, video_id: video_id })
                .then((response) => {
                  this.$swal({
                    icon: 'success',
                    title: this.$t("Base.Alert.Deleted!"),
                    text: this.$t("Base.Alert.removed_success"),
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.getList();
                })
            }
          })
        }else{
          this.$swal({
            title: this.$t("Base.Alert.Error"),
            text: this.$t("Base.Alert.not_enough_coin"),
            icon: 'warning',
            confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push({ name: 'MasterCoin' }).catch(() => { });
            }
          })
        }
        
      } else {
        this.openPermissionDenid()
      }
    },
    getStaticDataInForm() {
      this.$http
        .get(`/api/channel/studio/videos/get-static-data-form`)
        .then((response) => {
          let lang = [];
          response.data.languages.map((item) => {
            lang.push({ label: item.name, value: item.abbreviation });
          });
          this.AudioLanguage = lang;

          let category = [];
          response.data.categories.map((item) => {
            category.push({ label: item.name, value: item.category_id });
          });
          this.Category = category;
        })
    },
    openPermissionDenid() {
      this.$swal({
        title: this.$t("Base.Alert.Error"),
        text: this.$t("Navbar.Title.need_golden"),
        icon: 'warning',
        confirmButtonText: this.$t("Navbar.Button.upgrade_account"),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.push("/pricing").catch(() => { });
        }
      })
    },
    openYoutube(video_id) {
      window.open(`https://www.youtube.com/watch?v=${video_id}`, "_blank");
    },
    getListPendingVideoUplaod() {
      this.$http
        .get(`/api/channel/studio/videos/upload-pending?channel_id=${this.$route.params.channel_id}`)
        .then((response) => {
          if(response.data.status){
            this.pending_upload_list = response.data.data;
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
